export enum PartnerBrandingName {
  Flasha = 'flasha',
  Fpma = 'fpma',
  ScotlandHealth = 'scotland-health',
  SentaraAlbemarle = 'sentara-albemarle',
  CarolinaEastHealthSystem = 'carolinaeast',
  OnslowMemorialHospital = 'onslow-memorial-hospital',
  AppalachianRegionalHealthcareSystem = 'appalachian-regional',
  ApolloMD = 'apollomd',
  HughChathamHealth = 'hugh-chatham',
  CarteretHealthCare = 'carteret',
  RandolphHealth = 'randolph-health',
  IredellHealthSystem = 'iredell',
  ECUHealth = 'ecu-health',
  CaroMontHealth = 'caromont-health',
  FirstHealth = 'firsthealth',
  USAcuteCareSolutions = 'us-acute-care',
  WakeMed = 'wakemed',
  DukeHealth = 'duke-health',
  MissionHealth = 'mission-health',
  ConeHealth = 'cone-health',
  AtriumHealth = 'atrium-health',
  Labcorp = 'labcorp',
  UNCHealth = 'unc-health',
  Novant = 'novant',
}

export const getPartnerName = (partner: PartnerBrandingName) => {
  const index = Object.values(PartnerBrandingName).indexOf(partner);
  const key = Object.keys(PartnerBrandingName)[index];
  return key.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3');
};
